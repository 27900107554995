import { Flex } from '@chakra-ui/core'
import { rem } from 'design'
import { LargeButtonPrimary } from 'design/components/Button/Button'
import { Text, IconCheck, Lockup } from '@butcherbox/freezer'
import Input from 'design/components/Input/Input'
import Select from 'design/components/Select/Select'
import Textarea from 'design/components/Textarea/Textarea'
import { Field, Form, withFormik } from 'formik'
import React, { useRef } from 'react'
import * as Yup from 'yup'
import { corporateGiftOptions } from '~/data/corporate-sales-gift-quantity-data'

const STRING_FIELD_MAX_LENGTH = 30
const FORM_FIELD_PADDING = 24
const CORPORATE_FORM_ID = 'corporate-form'

const CorporateSalesLeadValidationSchema = Yup.object().shape({
  first_name: Yup.string()
    .required('First Name Required')
    .max(
      STRING_FIELD_MAX_LENGTH,
      `Comment should be fewer than ${STRING_FIELD_MAX_LENGTH} characters`
    ),
  last_name: Yup.string()
    .required('Last Name Required')
    .max(
      STRING_FIELD_MAX_LENGTH,
      `Comment should be fewer than ${STRING_FIELD_MAX_LENGTH} characters`
    ),
  email: Yup.string()
    .email('Please enter a valid email')
    .required('Email Required'),
  phone: Yup.string()
    .matches(/(\D*\d){10,11}/, 'Enter a valid phone number')
    .required('Phone Number Required'),
  company: Yup.string()
    .required('Business Name Required')
    .max(
      STRING_FIELD_MAX_LENGTH,
      `Comment should be fewer than ${STRING_FIELD_MAX_LENGTH} characters`
    ),
  corporateGiftDetails__c: Yup.string().max(
    120,
    'Comment should be fewer than 120 characters'
  ),
  Number_Of_Boxes__c: Yup.string()
    .oneOf(
      corporateGiftOptions.map((item) => item.value),
      'Please select one of the options'
    )
    .required('Gift quantity required'),
})

export const CorporateSalesForm = ({
  shouldShowForm,
  setIsFormSubmitted,
}: {
  shouldShowForm: boolean
  setIsFormSubmitted: (isFormSubmitted: boolean) => void
}) => {
  const formEl = useRef(null)

  const doHtmlFormPost = React.useCallback(() => {
    formEl.current.submit()
    setTimeout(() => setIsFormSubmitted(true), 500)
  }, [formEl, setIsFormSubmitted])

  const MyForm = withFormik({
    handleSubmit: doHtmlFormPost,
    validationSchema: CorporateSalesLeadValidationSchema,
    validateOnBlur: false,
  })((props) => {
    const { values, isSubmitting, handleSubmit } = props

    return (
      <>
        <Form
          action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
          id={CORPORATE_FORM_ID}
          method="POST"
          onSubmit={handleSubmit}
          ref={formEl}
          target="dummyIFrame"
        >
          <Flex
            alignItems="center"
            flexDir="column"
            py={{ base: rem(48), desktop: rem(72) }}
          >
            {shouldShowForm ? (
              <>
                <Flex
                  flexDir="column"
                  maxWidth={rem(595)}
                  mb={rem(32)}
                  textAlign={{ base: 'left', desktop: 'center' }}
                  width={{ base: rem(327), desktop: 'auto' }}
                >
                  <Lockup>
                    <Text component="h2" id="get-started" variant="H1Bold">
                      Get started
                    </Text>
                    <Text variant="H3Regular">
                      We're here to help you surprise and delight! Share a few
                      details, and we'll be in touch within 2 business days.
                    </Text>
                  </Lockup>
                </Flex>

                <Flex
                  flexDir="column"
                  justifySelf="center"
                  width={{ base: rem(327), desktop: rem(432) }}
                >
                  <Flex
                    flexDir={{ mobile: 'column', desktop: 'row' }}
                    mb={rem(FORM_FIELD_PADDING)}
                  >
                    <input name="oid" type="hidden" value="00Df40000047s9m" />
                    <input
                      name="retURL"
                      type="hidden"
                      value="https://butcherbox.com:8000/corporate-gifting"
                    />
                    <input name="debug" type="hidden" value="0" />
                    <input
                      name="name"
                      type="hidden"
                      value={values.first_name + values.last_name}
                    />
                    <input name="lead_source" type="hidden" value="Website" />

                    <Field
                      // eslint-disable-next-line jsx-a11y/no-autofocus
                      component={Input}
                      floatLabel
                      label="First Name"
                      mb={{ mobile: rem(FORM_FIELD_PADDING), desktop: '0' }}
                      mr={{ mobile: '0', desktop: rem(4) }}
                      name="first_name"
                      value={values.first_name}
                    />

                    <Field
                      component={Input}
                      floatLabel
                      label="Last Name"
                      ml={{ mobile: '0', desktop: rem(4) }}
                      name="last_name"
                      value={values.last_name}
                    />
                  </Flex>
                  <Field
                    component={Input}
                    floatLabel
                    label="Email"
                    mb={rem(FORM_FIELD_PADDING)}
                    name="email"
                    type="email"
                    value={values.email}
                  />
                  <Field
                    component={Input}
                    floatLabel
                    label="Phone Number"
                    mb={rem(FORM_FIELD_PADDING)}
                    name="phone"
                    type="tel"
                    value={values.phone}
                  />
                  <Field
                    component={Input}
                    floatLabel
                    label="Business name"
                    mb={rem(FORM_FIELD_PADDING)}
                    name="company"
                    value={values.company}
                  />
                  <Field
                    backgroundColor="white"
                    component={Textarea}
                    floatLabel
                    height={rem(149)}
                    label="Please provide further details"
                    mb={rem(FORM_FIELD_PADDING)}
                    name="corporateGiftDetails__c"
                    value={values.corporateGiftDetails__c}
                  />
                  <Field
                    component={Select}
                    floatLabel
                    label="How many gifts?"
                    mb={rem(FORM_FIELD_PADDING)}
                    name="Number_Of_Boxes__c"
                    options={corporateGiftOptions}
                    value={values.Number_Of_Boxes__c}
                  />

                  <LargeButtonPrimary isDisabled={isSubmitting} type="submit">
                    Submit
                  </LargeButtonPrimary>
                </Flex>
              </>
            ) : (
              <Flex
                alignItems="center"
                display="flex"
                flexDir="column"
                maxW={rem(580)}
                mb={{ desktop: rem(50), mobile: '0' }}
                mt={rem(24)}
                mx={rem(24)}
                textAlign="center"
              >
                <Lockup
                  alignItems={'center'}
                  display={'flex'}
                  flexDirection={'column'}
                  textAlign={'center'}
                >
                  <IconCheck
                    customColor={{ base: 'success' }}
                    marginBottom={24}
                    size="small"
                  />

                  <Text variant="H3Bold">Thank you!</Text>
                  <Text variant="H3Regular">
                    Your request has been received, and a corporate gifting
                    specialist will be in touch soon.
                  </Text>
                </Lockup>
              </Flex>
            )}
          </Flex>
        </Form>
        <iframe
          id="dummyIFrame"
          name="dummyIFrame"
          style={{ display: 'none' }}
          title="dummyIFrame"
        ></iframe>
      </>
    )
  })

  return <MyForm />
}
