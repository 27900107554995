export const corporateGiftOptions = [
  { value: '1-5', label: '1-5', 'aria-label': '1-5' },
  { value: '6-10', label: '6-10', 'aria-label': '6-10' },
  { value: '11-20', label: '11-20', 'aria-label': '11-20' },
  { value: '21-100', label: '21-100', 'aria-label': '21-100' },
  {
    value: 'more_than_100',
    label: 'More than 100',
    'aria-label': 'More than 100',
  },
]
