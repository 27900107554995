import React, { ReactElement } from 'react'
import { Box, Flex } from '@chakra-ui/core'
import theme, { rem, styled } from 'design'
import { IconName } from 'design/components/Icons/types'
import {
  IconProps as FreezerIconProps,
  IconSend,
  IconShipping,
  IconGift,
  Text,
  Button,
  Lockup,
  Card,
  CardMedia,
  CardBody,
  CardBadge,
  CardGrid,
} from '@butcherbox/freezer'
import { LI, List } from 'design/components/List/List'
import {
  H1,
  H2,
  H3,
  ResponsiveText,
  Subhead,
} from 'design/components/Typography/Typography'
import { graphql, Link, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import GatsbyImage, { FluidObject } from 'gatsby-image'
import { CTA_UNAUTHENTICATED_DEFAULT } from '~/data/constants'
import UnauthenticatedLayout from '~/layouts/UnauthenticatedLayout'
import { smoothScrollTo } from '~/utils/scroll'
import { CorporateSalesForm } from './CorporateSalesComponents/CorporateSalesForm'
import { IconArrowRight } from '@butcherbox/freezer'

const CORPORATE_FORM_ID = 'corporate-form'

export default function CorporateSales() {
  const {
    steakMarinade,
    grilledSalmon,
    grilledSalmonMobile,
    giftHeader,
    giftHeaderMobile,
    smoothieBox,
    seafood,
    steakLovers,
  } = useStaticQuery(graphql`
    query {
      steakMarinade: file(relativePath: { eq: "steak-marinade.png" }) {
        childImageSharp {
          fluid(maxWidth: 1009) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      grilledSalmon: file(relativePath: { eq: "grilled-salmon.png" }) {
        childImageSharp {
          fluid(maxWidth: 560) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      grilledSalmonMobile: file(relativePath: { eq: "grilled-salmon.png" }) {
        childImageSharp {
          fluid(maxWidth: 560, maxHeight: 325, cropFocus: CENTER) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      giftHeader: file(relativePath: { eq: "gift-header.png" }) {
        childImageSharp {
          fluid(maxWidth: 2160) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      giftHeaderMobile: file(relativePath: { eq: "gift-header-mobile.png" }) {
        childImageSharp {
          fluid(maxHeight: 480, cropFocus: SOUTH) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      steakLovers: file(relativePath: { eq: "steak-lovers.png" }) {
        childImageSharp {
          fluid(maxWidth: 320) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      seafood: file(relativePath: { eq: "seafood-box.png" }) {
        childImageSharp {
          fluid(maxWidth: 320) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      smoothieBox: file(relativePath: { eq: "smoothie-box.png" }) {
        childImageSharp {
          fluid(maxWidth: 320) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  type ISatisfactionListItems = {
    bullet: string
    text: string
    iconName: string
    icon: ReactElement<FreezerIconProps>
  }[]

  const satisfactionListItems = [
    {
      bullet: 'Get in touch',
      text:
        'Ready to surprise and delight? Provide some quick details, and a corporate gifting specialist will contact you within 2 business days.',
      iconName: 'paperAirplane',
      icon: (
        <IconSend customColor={{ base: 'slate', accent: 'spicedCrimson' }} />
      ),
    },
    {
      bullet: 'Place your order',
      text: `Your corporate gifting specialist will help you make sure everything is just right.  
        P.S. We offer discounts for bulk orders and repeat customers!`,
      iconName: 'freeGiftTwoColor',
      icon: (
        <IconGift customColor={{ base: 'slate', accent: 'spicedCrimson' }} />
      ),
    },
    {
      bullet: 'We deliver!',
      text:
        "It's our job to make the gifting process as seamless as possible. We follow through with each client to make sure everything is perfect (and delicious). ",
      iconName: 'deliveryTruck',
      icon: (
        <IconShipping
          customColor={{ base: 'slate', accent: 'spicedCrimson' }}
        />
      ),
    },
  ] as ISatisfactionListItems

  type IGiftBoxesCardsContent = {
    title: string
    description: string
    image: FluidObject
    showBadge: boolean
  }[]

  const giftBoxCardsContent = [
    {
      title: 'Butcherbox Favorites',
      description: `The Ultimate Steak Sampler Box has 10 of America's favorite steaks. You'll get 2 Ribeye steaks, 2 Filet Mignons, 2 NY Strip Steaks, and 4 Top Sirloin Steaks. All of our steaks are 100% grass-fed and grass-finished, and pasture-raised.`,
      image: steakLovers?.childImageSharp?.fluid,
      showBadge: false,
    },
    {
      title: 'Seafood box',
      description: `Get a selection of the best 100% wild-caught seafood. You'll get an 8oz Lobster pack, 2lb of Wild Caught Salmon, 1lb Wild-caught Sea Scallops, 2lb Cod, and 8 Salmon Burgers.`,
      image: seafood?.childImageSharp?.fluid,
      showBadge: false,
    },
    {
      title: 'Smoothie Box',
      description: `Vegan-friendly fruit and veggie smoothies. A selection of 20 fruit smoothies with flavors of Cacao, Green, Berry, and Clementine. New whey and vegan proteins available.`,
      image: smoothieBox?.childImageSharp?.fluid,
      showBadge: true,
    },
  ] as IGiftBoxesCardsContent

  const [isFormSubmitted, setIsFormSubmitted] = React.useState(false)

  return (
    <UnauthenticatedLayout>
      <Box aria-labelledby="hero" as="section">
        <StyledBackgroundImage
          aria-labelledby="hero"
          critical
          fadeIn={false}
          fluid={[
            giftHeaderMobile?.childImageSharp?.fluid,
            {
              ...giftHeader?.childImageSharp?.fluid,
              media: `(min-width: 481px)`,
            },
          ]}
          style={{ backgroundPosition: '' }}
        >
          <Flex
            justifyContent={{ mobile: 'center', desktop: 'flex-end' }}
            mr={{ desktop: rem(80), mobile: 'auto' }}
          >
            <Flex
              alignItems={{ base: 'center', desktop: 'flex-start' }}
              flexDir="column"
              height={{ base: rem(400), desktop: rem(500) }}
              justifyContent={{ desktop: 'center', base: 'left' }}
              mt={{ base: rem(40), desktop: 'auto' }}
              pl={{ desktop: rem(300), mobile: 'auto' }}
              px={rem(24)}
              textAlign={{ base: 'center', desktop: 'left' }}
            >
              <Box style={{ maxWidth: '564px' }}>
                <Lockup>
                  <Text variant="Eyebrow">Corporate Gifts</Text>
                  <Text variant="DisplayOne">
                    Discover the most delicious way to show your appreciation.
                  </Text>
                  <CorporateSalesCTA />
                </Lockup>
              </Box>
            </Flex>
          </Flex>
        </StyledBackgroundImage>
      </Box>
      <Flex
        aria-labelledby="how-it-works"
        as="section"
        justifyContent="center"
        mx={rem(32)}
        my={{ base: rem(40), desktop: rem(72) }}
        position="relative"
      >
        <Flex
          align-items={{ tablet: 'center' }}
          flexDir="column"
          maxW={rem(475)}
          mr={{ base: 0, desktop: rem(500) }}
          zIndex={theme.zIndices.header}
        >
          <Lockup>
            <Text id="how-it-works" variant="Eyebrow">
              How it works
            </Text>
            <Text marginBottom={32} variant="H1Bold">
              Gifting Made Easy
            </Text>
          </Lockup>
          <List mb={rem(32)} rowGap={rem(24)}>
            {satisfactionListItems.map((item, idx) => (
              <LI
                iconName={item.iconName as IconName}
                key={item.iconName}
                renderIcon={() => item.icon}
                size={rem(60)}
              >
                <Flex flexDir="column" ml={rem(44)}>
                  <H3 as="h4" fontWeight="700" mb={rem(8)} pl={rem(4)}>
                    {idx + 1}. {item.bullet}
                  </H3>
                  <H3 as="p" fontWeight="400">
                    {item.text}
                  </H3>
                </Flex>
              </LI>
            ))}
            <CorporateSalesCTA />
          </List>
        </Flex>

        <Box
          display={{ base: 'none', desktop: 'block' }}
          position="absolute"
          right={rem(-32)}
          top={rem(-105)}
          width={rem(1009)}
        >
          <GatsbyImage fluid={steakMarinade?.childImageSharp?.fluid} />
        </Box>
      </Flex>
      <Flex
        alignItems="center"
        aria-labelledby="curated-boxes"
        as="section"
        backgroundColor="bb.ivory"
        flexDir="column"
        justifyContent={'center'}
        py={{ desktop: rem(72), mobile: rem(48) }}
        textAlign="center"
      >
        <Box style={{ maxWidth: '500px' }}>
          <Lockup
            alignItems={'center'}
            display={'flex'}
            flexDirection={'column'}
            marginBottom={24}
            textAlign={'center'}
          >
            <Text component={'h2'} id="curated-boxes" variant="Eyebrow">
              Curated boxes
            </Text>
            <Text component="h2" variant="H1Bold">
              Gifts with Great Taste
            </Text>
            <Text component={'p'} mx={28} variant="Body1Regular">
              With 20+ curated boxes to choose from, our high-quality, humanely
              raised meat makes the perfect gift.
            </Text>
            <Box
              background={theme.colors.bb.pesto}
              style={{ width: 'fit-content', padding: '6px 8px' }}
            >
              <Text color={'white'} id="curated-boxes" variant="Subhead1">
                Starting at $75
              </Text>
            </Box>
          </Lockup>
        </Box>

        <CardGrid
          columns={{ mobile: 1, tablet: 3 }}
          gap={{ mobile: 16, tablet: 24 }}
          marginBottom={32}
          mx={16}
        >
          {giftBoxCardsContent.map((item) => (
            <Card key={item.title}>
              <CardMedia
                alt={`An image of ${item.title}`}
                renderMedia={() => <GatsbyImage fluid={item.image} />}
              />
              <CardBody textAlign={'left'}>
                <Text variant="H2Bold">{item.title}</Text>
                <Text variant="Body1Regular">{item.description}</Text>
              </CardBody>
              {item.showBadge && (
                <CardBadge
                  position="tl"
                  text="Vegan"
                  theme="openSea"
                  variant="text"
                />
              )}
            </Card>
          ))}
        </CardGrid>
        <CorporateSalesCTA />
      </Flex>
      <Flex
        alignContent="center"
        alignItems="center"
        aria-labelledby="custom-boxes"
        as="section"
        flexDir={{ mobile: 'column-reverse', desktop: 'row' }}
        justifyContent="center"
        my={{ mobile: 'auto', tablet: rem(72) }}
      >
        <Flex
          flexDir="column"
          maxW={rem(400)}
          mb={{ mobile: rem(72), tablet: 'auto' }}
          mr={{ mobile: 0, tablet: rem(80) }}
          mt={{ mobile: rem(40), tablet: 'auto' }}
          px={rem(24)}
        >
          <Lockup>
            <Text component="h2" id="custom-boxes" variant="Eyebrow">
              Custom boxes
            </Text>
            <Text component={'h3'} variant="H1Bold">
              Perfectly Personalized
            </Text>
            <Text variant="H3Bold">
              Create the perfect assortment of high-quality, humanely raised
              meat for your gift boxes.
            </Text>

            <Box as="ul" listStyleType="none" mb={rem(16)} pl={rem(8)}>
              <CorporateSalesListItem>
                Fully customizable
              </CorporateSalesListItem>
              <CorporateSalesListItem>Bulk discounts</CorporateSalesListItem>
              <CorporateSalesListItem>
                2-4 week turnaround
              </CorporateSalesListItem>
              <CorporateSalesListItem>
                10 box minimum order
              </CorporateSalesListItem>
            </Box>
            <CorporateSalesCTA />
          </Lockup>
        </Flex>
        <Box
          ml={{ mobile: 0, tablet: rem(80) }}
          width={{ mobile: '100%', tablet: rem(350) }}
        >
          <DesktopSalmon fluid={grilledSalmon?.childImageSharp?.fluid} />
          <MobileSalmon fluid={grilledSalmonMobile?.childImageSharp?.fluid} />
        </Box>
      </Flex>
      <Flex
        alignItems={{ base: 'left', desktop: 'center' }}
        aria-labelledby="testimonial"
        as="section"
        backgroundColor="bb.openSea"
        color="white"
        flexDir="column"
        px={rem(24)}
        py={{ base: rem(40), desktop: rem(72) }}
        textAlign={{ base: 'left', desktop: 'center' }}
      >
        <Subhead as="h2" id="testimonial" mb={rem(16)} textAlign="left">
          People love butcherbox
        </Subhead>
        <figure>
          <ResponsiveText
            as="blockquote"
            desktop={H1}
            fontWeight="700"
            maxW={rem(790)}
            mb={rem(16)}
            mobile={H2}
          >
            {' '}
            “The execution is flawless, and they always follow through to make
            sure every employee has received their box and is happy! We couldn't
            ask for a better experience.”{' '}
          </ResponsiveText>
        </figure>

        <H3 as="p" fontWeight="700">
          Louie C.
        </H3>
        <H3 as="p" fontWeight="400">
          ButcherBox Corporate Gifter
        </H3>
      </Flex>
      <Box aria-labelledby="get-started" as="section">
        <CorporateSalesForm
          setIsFormSubmitted={setIsFormSubmitted}
          shouldShowForm={!isFormSubmitted}
        />
      </Box>

      <Flex
        alignItems={{ base: 'left', desktop: 'center' }}
        aria-labelledby="try-butcherbox"
        as="section"
        backgroundColor="bb.pesto"
        color="white"
        flexDir="column"
        pl={rem(24)}
        py={{ base: rem(40), desktop: rem(96) }}
      >
        <Subhead id="try-butcherbox" mb={rem(8)}>
          Don't forget to gift yourself
        </Subhead>
        <ResponsiveText
          as="h2"
          desktop={H1}
          fontWeight="700"
          maxW={rem(790)}
          mb={rem(16)}
          mobile={H2}
        >
          Get high-quality meat delivered to your door.
        </ResponsiveText>
        <H3>
          <Link to="/get-started">
            {CTA_UNAUTHENTICATED_DEFAULT}
            <IconArrowRight
              display={'inline'}
              marginLeft={4}
              size={'text'}
              variant={'inherit'}
              verticalAlign={'text-top'}
            />
          </Link>
        </H3>
      </Flex>
    </UnauthenticatedLayout>
  )
}

const CorporateSalesCTA = () => (
  <Button
    onClick={() => smoothScrollTo(`#${CORPORATE_FORM_ID}`)}
    width="standard"
  >
    Get Started
  </Button>
)

const DesktopSalmon = styled(GatsbyImage)`
  display: none;
  margin-bottom: 20px;
  ${(p) => p.theme.media.tablet || p.theme.media.desktop} {
    display: block;
  }
`

const MobileSalmon = styled(GatsbyImage)`
  display: block;

  ${(p) => p.theme.media.tablet || p.theme.media.desktop} {
    display: none;
  }
`

const CorporateSalesListItem = ({ children, ...props }) => {
  return (
    <H3
      _before={{
        color: 'bb.spicedCrimson',
        // @ts-ignore
        content: `'\\2022'`,
        display: 'inline-block',
        lineHeight: 'inherit',
        pr: rem(10),
        verticalAlign: 'middle',
      }}
      as="li"
      fontWeight="400"
      // @ts-ignore
      listStyle="none"
      {...props}
    >
      {children}
    </H3>
  )
}

const StyledBackgroundImage = styled(BackgroundImage)`
  ::before,
  ::after {
    background-position: bottom center;
  }

  ${(p) => p.theme.media.tablet} {
    ::before,
    ::after {
      background-position: unset;
    }
  }
`
