import { Box, BoxProps, PseudoBox } from '@chakra-ui/core'
import { rem, useTheme } from 'design'
import { Body } from 'design/components/Typography/Typography'
import { FieldProps } from 'formik'
import React from 'react'
import FormErrorMessage from 'design/components/FormErrorMessage/FormErrorMessage'

// TS was getting confused with the normal `onChange` typedef derived from React's default event signature
type SelectProps = BoxProps & {
  floatLabel?: boolean
  label: string
  options: Array<{ 'aria-label'?: string; label: string; value: string }>
  showDirectionArrow?: boolean
  required?: boolean
} & FieldProps

const Select: React.FC<SelectProps> = ({
  floatLabel,
  field,
  form,
  id,
  label,
  options,
  required,
  showDirectionArrow = true,
  ...props
}) => {
  const theme = useTheme()
  const hasError = form.submitCount > 0 && !!form.errors[field.name]
  const shouldFloat = floatLabel && field.value
  const errorId = `${id}-error`

  return (
    <Box {...props} data-what="select" pos="relative">
      {showDirectionArrow && (
        <PseudoBox
          _after={{
            content: '" "',
            bg: 'bb.stone',
            position: 'absolute',
            top: 0,
            right: rem(16 + 10 + 5),
            transform: 'translateY(18px)',
            height: rem(16),
            width: '1px',
            zIndex: 1,
          }}
          _before={{
            content: '""',
            borderTop: `5px solid ${theme.colors.bb.stone}`,
            borderLeft: `5px solid transparent`,
            borderRight: `5px solid transparent`,
            borderBottom: `5px solid transparent`,
            position: 'absolute',
            top: 0,
            right: rem(16),
            transform: 'translateY(24px)',
            zIndex: 1,
          }}
        />
      )}

      <Body
        alignItems="flex-start"
        aria-hidden
        color={!shouldFloat && field.value ? 'transparent' : 'bb.stone'}
        d="flex"
        fontSize={shouldFloat ? rem(12) : rem(16)}
        left={rem(16)}
        pointerEvents="none"
        pos="absolute"
        transform={shouldFloat ? 'translateY(3px)' : 'translateY(14px)'}
        transition="all 200ms ease"
      >
        {label}
      </Body>

      <PseudoBox
        _focus={{ borderColor: 'bb.spicedCrimson' }}
        _hover={{ borderColor: 'bb.spicedCrimson' }}
        appearance="none"
        aria-describedby={hasError ? errorId : ''}
        aria-label={label}
        as="select"
        bg="white"
        border="1px solid"
        borderColor={hasError ? 'ui.error' : 'bb.stone'}
        borderRadius={0}
        css={{
          '> option': {
            lineHeight: rem(18),
          },
          '> option:checked, > option:hover': {
            background: `${theme.colors.ui.inactive} !important`,
            padding: rem(18),
          },
        }}
        cursor="pointer"
        data-what="select"
        height={rem(48)}
        id={id}
        lineHeight={rem(24)}
        outline="none"
        pt={shouldFloat ? rem(20) : 0}
        px={rem(16)}
        // @ts-ignore
        required={required}
        w="100%"
        {...field}
      >
        <option value=""></option>
        {options.map((option) => (
          <option
            aria-label={option['aria-label']}
            data-what="select-option"
            key={option.value}
            value={option.value}
          >
            {option.label}
          </option>
        ))}
      </PseudoBox>

      {hasError && (
        <FormErrorMessage
          data-what="select-error"
          hasError={hasError}
          id={errorId}
          mt={rem(4)}
          pb={rem(4)}
          pos="absolute"
          textAlign="left"
          zIndex={1}
        >
          {form.errors[field.name]}
        </FormErrorMessage>
      )}
    </Box>
  )
}

export default Select
